import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "liens",
      "style": {
        "position": "relative"
      }
    }}>{`Liens`}<a parentName="h1" {...{
        "href": "#liens",
        "aria-label": "liens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h1>
    <p>{`Liens vers certaines choses qui me concernent...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maximerichard.dev/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Accueil`}</a>{` - Ce site web`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/liens"
        }}>{`Liens`}</a>{` - Cette page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/rmaximedev/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Twitter`}</a>{` - Principalement de la veille technique`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/zyhou/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`GitHub`}</a>{` - Mon profil avec tous mes projets`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/~zyhou",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`npm`}</a>{` - Mes packages open source`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/infos"
        }}>{`Infos`}</a>{` - Photos et biographie`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/maximerichard9/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`LinkedIn`}</a>{` - Mon profil / `}<abbr title="Curriculum vitæ">{`CV`}</abbr></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.twitch.tv/zyhou",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Twitch`}</a>{` - Pour me voir en direct`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contact"
        }}>{`Contact`}</a>{` - Me contacter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/zyhou/talks",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Talks`}</a>{` - Talks que j'ai donnés`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/setup"
        }}>{`Setup`}</a>{` - La configuration de mon ordinateur, terminal`}</li>
    </ul>
    <h2 {...{
      "id": "apéro-web-nancy",
      "style": {
        "position": "relative"
      }
    }}>{`Apéro Web Nancy`}<a parentName="h2" {...{
        "href": "#ap%C3%A9ro-web-nancy",
        "aria-label": "apéro web nancy permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aperowebnancy.netlify.app/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Site Web`}</a>{` - Le site du Meetup`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/aperowebnancy",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`GitHub`}</a>{` - L'organisation Github du Meetup`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discord.gg/Q8X8Bxq",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Discord Lorraine Tech Hub`}</a>{` - Communauté de développeur en Lorraine`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/AperoWebNancy",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Twitter Apéro Web`}</a>{` - N'hésitez pas à nous suivre`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.meetup.com/fr-FR/Aperos-Web-Nancy/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Meetup Apéro Web`}</a>{` - Venez nous voir `}<abbr title="In Real Life">{`IRL`}</abbr></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      